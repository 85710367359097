#cookiebar {
  width: 100%;
  height: auto;
  background: #444;
  color: #fff;
  padding: 5px 0;
  display: none;
  border-bottom: 2px solid #555;
  .container {
    background: none;
  }
  p {
    font-size: 1.25rem;
    float: left;
    margin-bottom: 0;
    margin-top: 6px;
    margin-right: 0;
  }
  button {
    padding: 5px 10px;
    font-size: 1.25rem;
    float: right;
  }
}
