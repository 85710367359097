.container {
  background: #fff;
}

.fullscreen-carousel {

  .caption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .caption-content {
    width: 45%;
    position: absolute;
    left: 5%;
    bottom: 0;
    height: 40%;
  }

  .caption-title {
    font-size: 90px;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  }

  .owl-carousel .owl-nav .owl-prev {
    top: 40%;
    position: absolute;
    left: 15px;
    background: transparent;
    display: block;
    font-size: 4.2rem;
    transition: all .3s;
    pointer-events: all;
  }

  .owl-carousel .owl-nav .owl-next {
    top: 40%;
    position: absolute;
    right: 15px;
    background: transparent;
    display: block;
    font-size: 4.2rem;
    transition: all .3s;
    pointer-events: all;
  }
}

.content-block-article {

  .product-button {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
  }

  .badge-text {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    color: #fff;
    font-size: 20px;
    background: $brand-danger;
    transition: opacity .2s ease-in-out;
    padding: 5px;

    @media (max-width: $screen-sm-max) {
      opacity: 1;
      white-space: nowrap;
    }

    z-index: 5000;
  }

  .image-wrapper {
    position: relative;

    &:hover, &:focus {
      .badge-text {
        opacity: 1;
      }
    }
  }

  .product-eekLabels {
    position: absolute;
    left: 0;
    bottom: 5px;
    background-color: $brand-danger;
    color: #fff;
    font-size: 10px;
    text-align: center;
    font-weight: bolder;
    border: none;
    display: flex;
    align-items: center;
    padding: 3px 8px 3px 6px;

    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: #fff transparent transparent transparent;
      content: "";
      margin-left: 8px;
      transition: transform .1s ease-in-out;
    }
  }

  .caption-wrapper {
    position: relative;
  }

  .product-eekTable {
    position: absolute;
    top: -5px;
    left: 0;
    min-width: 100%;
    width: auto;
    z-index: 1000;

    .table {
      margin-bottom: 0;
      background: #ffffff;
      border: 1px solid $brand-danger;
      font-size: 10px;

      thead {
        background: $brand-danger;
        color: #ffffff;
        font-weight: bolder;
      }

      td {
        padding: 3px 6px;
        white-space: nowrap;
      }
    }
  }

  .price {
    font-size: 50px;
    text-align: right;
    margin-bottom: -10px;

    @media (max-width: $screen-sm-max) {
      font-size: 40px;
    }
  }

  .original-price {
    font-size: 25px;
    text-align: right;

    @media (max-width: $screen-sm-max) {
      font-size: 20px;
    }
  }

  .product-name {
    margin-bottom: 10px;
  }

  .price-prefix {
    font-size: 14px;
    color: #cecece;
    text-align: right;
  }

  .signet {
    position: absolute;
    bottom: 10px;

    &.signet-left {
      left: 10px;
    }

    &.signet-right {
      right: 10px;
    }

    &.signet-offset {
      bottom: 35px;
    }
  }

  .mobile-signet > img {
    max-width: 100px;
  }
}

.img-center {
  margin: auto;
}

.white-background {
  background-color: white;
}
