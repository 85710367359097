$icon-font-path:          "../fonts/Glyphicons/";
$fa-font-path:            "../fonts/FontAwesome";

$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;

$body-bg:           #FFFFFF;
$text-color:        #000;

$grid-gutter-width: 10px;

$container-large-desktop:      (1130px + $grid-gutter-width);

$thumbnail-padding:           0;
$thumbnail-bg:                #fff;
$thumbnail-border:            transparent;

$font-family-serif:       'Roboto Slab', serif;
$font-family-base:        $font-family-serif