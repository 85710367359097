.hotspots-wrapper {
  .image-hotspots-area {
    position: relative;
    display: block;
    line-height: 0;

    ul.image-hotspots {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;

        a.hotspot {
          list-style: none;
          position: absolute;
          text-decoration: none;

          .custom-marker {
            width: 45px;
            height: 45px;
            cursor: pointer;
            overflow: hidden;
            z-index: 11;
            border-radius: 50%;
            background-color: #e30615;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.45);

            &:before {
              content: '+';
              display: block;
              line-height: 42px;
              text-align: center;
              color: #ffffff;
              font-size: 30px;
            }

            &:hover {
              background-color: #ffffff;

              &:before {
                content: '-';
                color: #e30615;
              }
            }
          }
        }
      }

      .popover {
        max-width: 400px;
      }
    }
  }

  .hotspot-info-dialogs {
    display: none;
  }
}

.image-hotspots-popover-content {
  .hotspot-text {
    margin-bottom: 5px;
  }
}

@media (max-width: $screen-sm-max) {
  .hotspots-wrapper {
    .image-hotspots-area {
      ul.image-hotspots {
        display: none;
      }
    }
  }

  .image-hotspots-popover-content {
    h4, .h4 {
      margin-top: 0;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .hotspot-product-price {
      font-size: 14px;
    }

    .hotspot-product-link {
      font-size: 14px;
    }
  }
}
